
import axios from "axios";
import { BigNumber, ethers } from "ethers";
import { create as ipfsHttpClient } from "ipfs-http-client";
import ERC20 from "../../artifacts/@openzeppelin/contracts/token/ERC20/extensions/IERC20Metadata.sol/IERC20Metadata.json";
import ERC721 from "../../artifacts/contracts/WineNFT.sol/WineNFT.json";
import ERC721Enumerable from "../../artifacts/@openzeppelin/contracts/token/ERC721/extensions/IERC721Enumerable.sol/IERC721Enumerable.json";
import NFTMarketplace from "../../artifacts/contracts/NFTMarketplace.sol/NFTMarketplace.json";
import MembershipPublisher from "../../artifacts/contracts/NFTPublisher.sol/NFTPublisher.json";

export const ServiceType = {
  Local: 0,
  Testnet: 1,
  Alpha: 2,
  Main: 3,
};

const chain = {
  polygon: {
    chainId: "0x89",
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: [
      "https://polygon-rpc.com",
      "https://rpc-mainnet.maticvigil.com",
      "https://matic-mainnet-full-rpc.bwarelabs.com"
    ]
  },
  mumbai: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    rpcUrls: [
      "https://matic-mumbai.chainstacklabs.com",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
      "https://rpc-mumbai.maticvigil.com"
    ]
  },
  localhost: {
    chainId: "0x539",
    chainName: "Localhost 8548",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    rpcUrls: [
      "http://localhost:8545",
    ]
  }
};

const configurations = {
  main: {
    type: ServiceType.Main,
    chain: chain.polygon,
    contract: {
      market: {
        wine: "0xe88C5A969C7661a8944F5C6a36b20EE2d6d42b2C",
        membership: "0x5152e249743b6D4D5Fb95E2195B22b60e80AAC1C",
      },
      collection: {
        wine: "0x1b3Da4f0111A2ff1809fDC9E9905b5bFD806B671",
        membership: "0xdc0C7317f5D7855fc6f67eac4fF03C3cbc20f2a8"
      },
      currency: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      publisher: "0x5909d7b0Edf211B7C467E57d01e19CBAa9F96B1A"
    }
  },
  alpha: {
    type: ServiceType.Alpha,
    chain: chain.polygon,
    contract: {
      market: {
        wine: "0x08e6Bc44d853BB8f00339bc1089201bcd61D1678",
        membership: "0xF4c705EE6De955325A8837515D5ae6cCd403a55B",
      },
      collection: {
        wine: "0x9684D6E8b33Fe7bd0F55d94618e18a7F71aC022e",
        membership: "0xe05e0c15ef68865c935223F71CCf450B598eF667"
      },
      currency: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
      publisher: "0xF57Cf3ddb3915fFB82964648F2CD0bF49908cB3b"
    }
  },
  testenet: {
    type: ServiceType.Testnet,
    chain: chain.mumbai,
    contract: {
      market: {
        wine: "0x5B0C96471cd3a8801CB950668Efa57d4DCf9BC4A",
        membership: "0xD30BB066C07BA608Bd88A51Bad3a9D5A111aF4B2",
      },
      collection: {
        wine: "0xF328798F4B9770e31E925C2478a833A187539f6A",
        membership: "0x399954b8E8247453c062ef540245F34ec09b16cB"
      },
      currency: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23",
      publisher: "0xeA4dFd3062a09F8A1bFb831197a07B2C2E9c1977"
    }
  },
  local: {
    type: ServiceType.Local,
    chain: chain.localhost,
    contract: {
      market: {
        wine: "0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9",
        membership: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
      },
      collection: {
        wine: "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512",
        membership: "0x9fE46736679d2D9a65F0992F2272dE9f3c7fa6e0"
      },
      currency: "0x5FbDB2315678afecb367f032d93F642f64180aa3",
      publisher: "0xf2849F7FefB3d79505fFCa92e5c41a355234Db1a"
    }
  }
};

async function wait(promise) {
  try {
    return (await promise).wait();
  } catch (e) {
    if (e.reason === "repriced" || e.code === ethers.utils.Logger.errors.TRANSACTION_REPLACED) {
      if (!e.cancelled)
        return e.receipt;
    }
    throw e;
  }
}

function filter(logs, signature) {
  const Event = new ethers.utils.Interface([signature]);
  return logs.map(v => {
    try {
      return Event.parseLog(v);
    } catch {
      return undefined;
    }
  }).filter(v => v !== undefined);
}

class Marketplace {
  constructor(parent, ensureConnection, toItemInfo, fetchMetadata) {
    this.configuration = parent.configuration;
    this.ensureConnection = (...args) => ensureConnection(...args);
    this.isNegociant = (...args) => parent.isNegociant(...args);
    this.getUserAddress = (...args) => parent.getUserAddress(...args);
    this.toItemInfo = toItemInfo;
    this.ipfs = parent.ipfs;
    this.fetchMetadata = fetchMetadata;
  }

  async isUserNegociant() {
    const { account } = await this.ensureConnection(true);
    return await this.isNegociant(account);
  }

  async registerNegociant(account) {
    const { market } = await this.ensureConnection(true);
    return await market.registerNegociant(account);
  }

  async unregisterNegociant(account) {
    const { market } = await this.ensureConnection(true);
    return await market.unregisterNegociant(account);
  }

  async offerNft(tokenId, offerPrice, progress) {
    progress("연결확인 중", -1);
    const { market, currency, account, collection } = await this.ensureConnection(true);
    if (offerPrice.gt(await currency.balanceOf(account))) {
      throw new UserFriendlyError("잔고가 부족합니다.");
    }

    if (!await market.checkExclusivity(tokenId, account)) {
      throw new UserFriendlyError("LCWC 멤버십을 소유해야 구매 가능합니다.");
    }

    progress("진행중", -1);
    let transaction = await currency.approve(
      market.address,
      offerPrice.add(await currency.allowance(account, market.address))
    );
    await wait(transaction);
    progress("진행중", -1);
    try {
      transaction = await market.makeOffer(tokenId, offerPrice);
    } catch (e) {
      if (e.reason === "must own membership")
        throw new UserFriendlyError("LCWC 멤버십을 소유해야 구매 가능합니다.");
      throw e;
    }
    const receipt = await wait(transaction);
    console.log("receipt");
    console.log(receipt);
    const event = filter(
      receipt.logs
      , "event Offered(address indexed collection, uint256 indexed tokenId, address indexed issuer, uint256 price)"
    ).find(v => v.args.tokenId.eq(tokenId) && v.args.collection === collection.address);
    console.log("event");
    console.log(event);
    if (!event) {
      throw Error(`failed to make offfer ${collection.address}/${tokenId}`);  //
    }
    progress("Make Offer가 완료되었습니다.", -1);
  }

  async buyNowNft(tokenId, progress) {
    progress("연결확인 중", -1);
    const { market, currency, account, collection } = await this.ensureConnection(true);
    const price = (await market.fetchNFTById(tokenId)).price;
    if (price.gt(await currency.balanceOf(account))) {
      throw new UserFriendlyError("잔고가 부족합니다.");
    }

    if (!await market.checkExclusivity(tokenId, account)) {
      throw new UserFriendlyError("LCWC 멤버십을 소유해야 구매 가능합니다.");
    }

    progress("진행중", -1);
    let transaction = await currency.approve(
      market.address,
      price.add(await currency.allowance(account, market.address))
    );
    await wait(transaction);
    progress("진행중", -1);
    try {
      transaction = await market.buy(tokenId);
    } catch (e) {
      if (e.reason === "must own membership")
        throw new UserFriendlyError("LCWC 멤버십을 소유해야 구매 가능합니다.", e);
      throw e;
    }

    const receipt = await wait(transaction);
    const buyer = await this.getUserAddress();
    const event = filter(
      receipt.logs
      , "event Sold(address indexed collection, uint256 indexed tokenId, address indexed buyer, uint256 price)"
    ).find(v => {
      return v.args.collection === collection.address
        && v.args.tokenId.eq(tokenId)
        && v.args.buyer === buyer
    })
    if (!event && (await collection.ownerOf(tokenId) !== buyer.address)) {
      throw Error(`failed to buy ${collection.address}/${tokenId}`);
    }
    progress("구매 완료", -1);
  }

  async cancelListing(tokenId, progress) {
    progress("연결확인 중", -1);
    const { market, collection } = await this.ensureConnection(true);
    progress("취소 요청 중", -1);
    const receipt = await wait(market.cancelListing(tokenId)); // smart Contract
    const event = filter(
      receipt.logs
      , "event Unlisted(address indexed collection, uint256 indexed tokenId, address indexed issuer)"
    ).find(v => v.args.tokenId.eq(tokenId) && v.args.collection === collection.address);
    if (!event && (await collection.ownerOf(tokenId) === market.address)) {
      throw Error(`failed to cancel listring ${collection.address}/${tokenId}`);
    }
    progress("취소 요청 완료", -1);
  }

  async acceptNftOffer(tokenId, buyerId, price, progress) {
    progress("연결확인 중", -1);
    const { market, collection } = await this.ensureConnection(true);

    if (await collection.state(tokenId) != ItemState.ForSale) {
      await wait(collection.sell(tokenId, price));
    }

    const receipt = await wait(market.acceptMarketSale(tokenId, buyerId, price));
    const event = filter(
      receipt.logs
      , "event Sold(address indexed collection, uint256 indexed tokenId, address indexed buyer, uint256 price)"
    ).find(v => v.args.tokenId.eq(tokenId) && v.args.collection === collection.address);
    if (!event && (await collection.ownerOf(tokenId) !== buyerId)) {
      throw Error(`failed to accept offer form ${buyerId}`);
    }
    progress("판매 수락 완료", -1);
  }

  async sellNft(tokenId, price, progress) {
    progress("연결확인 중", -1);
    const { currency, collection, market } = await this.ensureConnection(true);
    const unit = await currency.decimals();
    const sellPrice = ethers.utils.parseUnits(price, unit);
    progress("판매 등록 중", -1);
    const receipt = await wait(collection.sell(tokenId, sellPrice));
    const event = filter(
      receipt.logs
      , "event Listed(address indexed collection, uint256 indexed tokenId, address indexed seller, uint256 price)"
    ).find(v => v.args.tokenId.eq(tokenId) && v.args.collection === collection.address);
    if (!event && (await collection.ownerOf(tokenId) !== market.address)) {
      throw Error(`failed to list ${collection.address}/${tokenId}`);
    }
    progress("판매 등록 완료", -1);
  }

  async requestRedeemNft(tokenId, shouldCancel, progress) {
    progress("연결확인 중", -1);
    const { collection } = await this.ensureConnection(true);

    //구현부
    if (!shouldCancel) {
      progress("리딤 요청 중", -1);
      const transaction = await collection.redeem(tokenId);
      await wait(transaction);
      progress("리딤 요청 완료", -1);
    } else {
      progress("리딤 요청 취소 중", -1);
      const transaction = await collection.cancel(tokenId);
      await wait(transaction);
      progress("리딤 취소 완료", -1);
    }
  }
  
  async recoverNft(tokenId, holder, progress) {
    progress("리딤 복구 중", -1);
    const { collection, market } = await this.ensureConnection(true);
    const transaction = await market.recover(collection.address, tokenId, holder);
    await wait(transaction);
    progress("리딤 복구 완료", -1);
  }

  async fetchOffers(tokenId) {
    const { market } = await this.ensureConnection();
    const result = await market["fetchOffers(uint256)"](tokenId);
    return Array.from(result).sort((l, r) => r.timestamp - l.timestamp);
  }

  async ownerOf(tokenId) {
    const { collection } = await this.ensureConnection();
    return await collection.ownerOf(tokenId);
  }

  async fetchTotalSupply() {
    const { market } = await this.ensureConnection();
    return await market.totalSupply();
  }

  async* fetchItemInfos(index, count) {
    const { market, collection } = await this.ensureConnection();
    const items = await market["fetchNFTs(uint256,uint256)"](index, count);
    for (const item of items) {
      yield await this.toItemInfo(item, collection);
    }
  }

  async fetchItemInfoByTokenId(tokenId, withoutCache) {
    const { market, collection } = await this.ensureConnection();

    withoutCache = withoutCache || false;
    let network;
    if (this.configuration.chain === chain.polygon) {
      network = "polygon";
    } else if (this.configuration.chain === chain.mumbai) {
      network = "mumbai";
    }

    let item;
    try {
      if (!withoutCache && network) {
        const raw = await axios(
          `https://jx5i3tz56t.ap-northeast-1.awsapprunner.com/api/v1/fetchNFTById/${network}/${market.address}/${collection.address}/${tokenId}`
        ).then(v => v.data);

        item = market.interface.decodeFunctionResult(
          market.interface.functions["fetchNFTById(uint256)"]
          , raw
        )[0];
      }
    } catch (e) {
      if (e.reason !== "nonexistent token")
        console.debug(e);
    }

    if (item === undefined) {
      item = await market["fetchNFTById(uint256)"](tokenId);
    }

    return await this.toItemInfo(item, collection);
  }

  async fetchItemInfo(index) {
    for await (const value of this.fetchItemInfos(index, 1))
      return value;
    return undefined;
  }

  async fetchCountListedItems() {
    const { market } = await this.ensureConnection();
    return await market.getCountListedItems();
  }

  async fetchItemListed(index) {
    const { market, collection } = await this.ensureConnection();
    const list = await market["fetchItemsListed(uint256,uint256)"](index, 1);
    if (list.length === 0)
      return undefined;
    return await this.toItemInfo(list[0], collection);
  }

  async* fetchMyItemInfos() {
    const { market, collection } = await this.ensureConnection(true);

    for (let i = 0, step = 10; ;) {
      const list = await market["fetchMyNFTs(uint256,uint256)"](i, step);
      if (list.length === 0)
        break;
      for (const item of list) {
        yield await this.toItemInfo(item, collection);
      }

      i += list.length;
    }

    const userAddress = await this.getUserAddress();
    for (let i = 0, step = 10; ;) {
      const list = await market["fetchItemsListed(uint256,uint256)"](i, step);
      if (list.length === 0)
        break;
      for (const item of list) {
        const info = await this.toItemInfo(item, collection);
        if (info.owner === userAddress)
          yield info;
      }

      i += list.length;
    }
  }

  async mint(wineInfo, imageFile, progress) {
    const { market, currency, account } = await this.ensureConnection(true);

    progress(WineMintingStep.Start, -1);

    progress(WineMintingStep.ImageUpload, -1);
    const imageIpfs = await this.ipfs.add(imageFile, {
      pin: false,
      progress: (bytes) => {
        const ratio = bytes / imageFile.size;
        progress(WineMintingStep.Progress, ratio);
        console.log(`image Upload:  ${ratio}<<== ${bytes} / ${imageFile.size} `);
      }
    }).catch(e => {
      throw new UserFriendlyError("이미지 업로드에 실패하였습니다.", e);
    });

    progress(WineMintingStep.CreateMetaData, 1);
    const meta = {
      name: wineInfo.name,
      description: wineInfo.description,
      image: `ipfs://${imageIpfs.path}`,
      external_url: "https://www.winex.ai",
      properties: {
      }
    };

    if (wineInfo.appendInfo) {
      const properties = wineInfo.appendInfo;
      for (const key in properties) {
        if (properties[key]) {
          meta.properties[key] = properties[key];
        }
      }
    }

    const metaJson = JSON.stringify(meta);
    const metaSize = new Blob([metaJson]).size;
    const metaIpfs = await this.ipfs.add(metaJson, {
      pin: false,
      progress: (bytes) => {
        const ratio = bytes / metaSize;
        progress(WineMintingStep.ImageUpload, ratio);
      }
    }).catch(e => {
      console.log(e)
      throw Error(WineMintingStep.Fail_MetadataUpload)
    });

    const tokenUri = `ipfs://${metaIpfs.path}`;
    const unit = await currency.decimals();
    const price = ethers.utils.parseUnits(String(wineInfo.price), unit);

    progress(WineMintingStep.Minting, -1);

    const transaction = await market["mint(string,uint256,uint256)"](tokenUri, price, wineInfo.count);
    const receipt = await wait(transaction);
    const events = filter(
      receipt.logs
      , "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)"
    ).filter((v) => {
      return v.args.from === ethers.constants.AddressZero && v.args.to === account;
    });

    progress(WineMintingStep.CheckOwner, -1);
    if (events.length === 0) {
      throw new UserFriendlyError("민팅 결과를 확인할 수 없습니다.");
    }

    let error;
    if (this.configuration.type === ServiceType.Main) {
      for (let i = 0; i < 5; ++i) {
        try {
          await this.ipfs.pin.add(metaIpfs.cid);
          await this.ipfs.pin.add(imageIpfs.cid);
          error = undefined;
          break;
        } catch (e) {
          error = new UserFriendlyError("메타데이터의 IPFS에 고정에 실패하였습니다.", e);
        }
      }
    }

    let keys = [];
    for (const event of events) {
      const { key, owner } = await this.fetchItemInfoByTokenId(event.args.tokenId);
      if (owner !== account)
        error = new UserFriendlyError("민팅에 성공하였지만 소유권에 이상이 있습니다.", error);
      keys.push(key);
    }

    console.debug(keys);
    if (error)
      throw error;

    progress(WineMintingStep.End, -1);
    return keys;
  }
}

class ContractApi {
  configuration = configurations.testenet;
  provider = undefined;
  default = {
    market: {
      wine: null, membership: null
    }, currency: null, collection: null, membership: null, publisher: null
  };
  ipfs = ipfsHttpClient({ url: `https://reverseproxy.winex.ai/ipfs/api/v0` });

  decimals = ethers.utils.parseEther("1");
  exchangeRate = 1200;
  refreshRate = 5 * 60 * 1000;
  timeoutContract = 60 * 1000; // 60초

  constructor() {
    this.assign(null);
    this.doUpdateExchangeRate();
    this.updateExchangeRate();
  }

  getServiceType() {
    return this.configuration.type;
  }

  async assign(signerOrProvider) {
    let signer = signerOrProvider ?? this.provider;
    if (!signer) {
      let candidates = [];
      const chain = this.configuration.chain;
      const count = Math.min(3, chain.rpcUrls.length);
      const offset = Math.trunc(Math.random() * chain.rpcUrls.length) % chain.rpcUrls.length;
      for (let i = 0; i < count && !this.provider; ++i) {
        const index = (offset + i) % chain.rpcUrls.length;
        try {
          const url = chain.rpcUrls[index];
          const provider = new ethers.providers.JsonRpcProvider(url);
          const startTime = Date.now();
          await Promise.race([
            provider.send("eth_getBlockByNumber", ["latest", false])
            , new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), 500))
          ]);
          candidates.push({
            latecny: Date.now() - startTime
            , provider, url
          });
        } catch {
          /*eslint no-empty: "error"*/
        }
      }

      if (this.provider) {
        signer = this.provider;
      } else {
        let candidate;
        if (candidates.length === 0) {
          const url = chain.rpcUrls[offset];
          candidate = {
            latecny: Number.MAX_SAFE_INTEGER
            , provider: new ethers.providers.JsonRpcProvider(url)
            , url
          };
        } else {
          candidate = candidates.reduce((l, r) => {
            return l.latecny < r.latecny ? l : r;
          })
        }

        signer = this.provider = candidate.provider;
        console.debug(`Selected RPC: ${candidate.url}`);
      }
    }

    const market = {
      wine: new ethers.Contract(
        this.configuration.contract.market.wine, NFTMarketplace.abi, signer
      ),
      membership: new ethers.Contract(
        this.configuration.contract.market.membership, NFTMarketplace.abi, signer
      )
    };

    const collection = new ethers.Contract(
      this.configuration.contract.collection.wine, ERC721.abi, signer
    );
    const currency = new ethers.Contract(
      this.configuration.contract.currency, ERC20.abi, signer
    );
    const decimals = await currency.decimals();

    const membership = new ethers.Contract(
      this.configuration.contract.collection.membership, ERC721.abi, signer
    );

    const publisher = new ethers.Contract(
      this.configuration.contract.publisher, MembershipPublisher.abi, signer
    );

    this.default = {
      market, currency, collection, membership, publisher
    };
    this.decimals = decimals;
  }

  async login(onDisconnect) {
    const connection = window.ethereum;
    const provider = new ethers.providers.Web3Provider(connection);
    const chain = this.configuration.chain;

    if (onDisconnect) {
      const wrapped = { onDisconnect };
      const callback = (arg) => {
        let message = null;
        if (typeof (arg) === "string") {
          if (arg !== chain.chainId)
            message = `not supported network: ${arg}`;
        } else if (Array.isArray(arg)) {
          if (arg.length === 0)
            message = "account disconnected";
        } else if ("message" in arg) {
          message = arg.message;
        }
        try {
          if (message !== null) {
            if (wrapped.onDisconnect)
              wrapped.onDisconnect(message);
          }
        } finally {
          if (message !== null) {
            wrapped.onDisconnect = null;
            connection.removeListener("disconnect", callback);
            connection.removeListener("chainChanged", callback);
            connection.removeListener("accountsChanged", callback);
          }
        }
      }

      connection.on("disconnect", callback);
      connection.on("chainChanged", callback);
      connection.on("accountsChanged", callback);
    }

    if (chain !== chain.localhost) {
      // 네트워크 추가
      await provider.send('wallet_addEthereumChain', [chain]);
      // 네트워크 전환
      await provider.send("wallet_switchEthereumChain", [{ chainId: chain.chainId }]);
    }
    // 지갑 연결 요청
    await provider.send("eth_requestAccounts", []);

    if (!this.isConnected())
      await this.assign(null);

    const signer = provider.getSigner();
    return {
      market: {
        wine: await this.default.market.wine.connect(signer),
        membership: await this.default.market.membership.connect(signer)
      },
      currency: await this.default.currency.connect(signer),
      collection: await this.default.collection.connect(signer),
      membership: await this.default.membership.connect(signer),
      publisher: await this.default.publisher.connect(signer),
      account: ethers.utils.getAddress(await signer.getAddress())
    };
  }

  isConnected() {
    return this.default.currency && this.default.collection && this.default.membership;
  }

  getMembershipMarket() {
    return new Marketplace(
      this, async (withWallet) => {
        const result = await this.ensureConnection(withWallet);
        return {
          ...result, market: result.market.membership, collection: result.membership
        }
      }
      , (...args) => this.toMembershipInfo(...args)
      , async (tokenId) => {
        const { membership } = await this.ensureConnection();
        const meta = await this.fetchMetadata(membership, tokenId);
        if (meta?.image) {
          meta.image = meta.image.replace(/^ipfs:\/\//, "https://lcwc.infura-ipfs.io/ipfs/");
        }
        return meta;
      }
    );
  }

  getWineMarket() {
    return new Marketplace(
      this, async (withWallet) => {
        const result = await this.ensureConnection(withWallet);
        return {
          ...result, market: result.market.wine, collection: result.collection
        }
      }
      , (...args) => this.toWineInfo(...args)
      , async (tokenId) => {
        const { collection } = await this.ensureConnection();
        const meta = await this.fetchMetadata(collection, tokenId);
        if (meta?.image) {
          meta.image = meta.image.replace(/^ipfs:\/\//, "https://winex.infura-ipfs.io/ipfs/");
        }
        return meta;
      }
    );
  }

  async fetchNegociants() {
    return await this.default.market.wine.negociants();
  }

  async isNegociant(address) {
    return address === await this.default.market.wine.owner()
      || await this.default.market.wine.isNegociant(address);
  }

  async getUserAddressByContract(contract) {
    const signer = contract?.provider.getSigner();
    if (signer)
      return await signer.getAddress()
        .then(v => ethers.utils.getAddress(v))
        .catch(() => undefined);
    return undefined;
  }

  async getUserAddress() {
    if (!window.ethereum)
      return undefined;
    return window.ethereum.request({ method: "eth_accounts" })
      .then(res => ethers.utils.getAddress(res[0]))
      .catch(() => undefined);
  }

  async ensureConnection(withWallet) {
    if (withWallet)
      return await this.login();
    if (!this.isConnected())
      await this.assign(null);
    return {
      market: {
        wine: this.default.market.wine,
        membership: this.default.market.membership
      },
      currency: this.default.currency,
      collection: this.default.collection,
      membership: this.default.membership,
      publisher: this.default.publisher
    };
  }

  async fetchMetadata(collection, tokenId, withoutCache) {
    withoutCache = withoutCache || false;

    let network;
    if (this.configuration.chain === chain.polygon) {
      network = "polygon";
    } else if (this.configuration.chain === chain.mumbai) {
      network = "mumbai";
    }

    let meta;
    try {
      if (!withoutCache && network) {
        meta = await axios(
          `https://jx5i3tz56t.ap-northeast-1.awsapprunner.com/api/v1/metadata/${network}/${collection.address}/${tokenId}`
        ).then(v => v.data);
      }
    } catch (e) {
      if (e.reason !== "nonexistent token")
        console.debug(e);
    }

    if (typeof meta !== "object") {
      let tokenUri = await collection.tokenURI(tokenId).catch(() => { });
      tokenUri = tokenUri.replace(/^ipfs:\/\//, "https://winex.infura-ipfs.io/ipfs/");
      meta = tokenUri ? await axios.get(tokenUri).then(v => v.data).catch(() => { return {}; }) : {};
    }

    meta.key = `${collection.address}/${tokenId}`;
    return meta;
  }

  async toMembershipInfo(item, collection) {
    let { tokenId, price, state, seller } = item;
    const meta = await this.fetchMetadata(collection, tokenId);
    if (meta?.image) {
      meta.image = meta.image.replace(/^ipfs:\/\//, "https://lcwc.infura-ipfs.io/ipfs/");
    }

    meta.properties = {};
    for (const attribute of meta.attributes || []) {
      meta.properties[attribute.trait_type] = attribute.value;
    }

    const minter = (await collection.info(tokenId)).negociant;
    // 판매중일 때는 마켓으로 소유권이 넘어가기에 seller를 확인하여 실 소유자를 파악
    const owner = (seller && !BigNumber.from(seller).isZero()) ? seller : item.owner;
    price = ethers.utils.formatUnits(price, this.decimals);
    return {
      ...meta,
      minter,
      owner: ethers.utils.getAddress(owner),
      key: `${ethers.utils.getAddress(collection.address)}/${tokenId}`,
      status: this.toWineInfoStatus(state),
      state,
      price: {
        coin: price, won: this.toWon(price), origin: item.price
      },
      timestamp: {
        created: item.timestamp.created.toNumber() * 1000,
        listed: item.timestamp.listed.toNumber() * 1000,
        redeemed: item.timestamp.redeemed.toNumber() * 1000
      },
    }
  }

  async toWineInfo(item, collection) {
    let { tokenId, price, state, seller } = item;
    const meta = await this.fetchMetadata(collection, tokenId);
    if (meta?.image) {
      meta.image = meta.image.replace(/^ipfs:\/\//, "https://winex.infura-ipfs.io/ipfs/");
    }

    const minter = (await collection.info(tokenId)).negociant;
    // 판매중일 때는 마켓으로 소유권이 넘어가기에 seller를 확인하여 실 소유자를 파악
    const owner = (seller && !BigNumber.from(seller).isZero()) ? seller : item.owner;
    price = ethers.utils.formatUnits(price, this.decimals);
    return {
      ...meta,
      minter,
      owner: ethers.utils.getAddress(owner),
      key: `${ethers.utils.getAddress(collection.address)}/${tokenId}`,
      status: this.toWineInfoStatus(state),
      state,
      price: {
        coin: price, won: this.toWon(price), origin: item.price
      },
      timestamp: {
        created: item.timestamp.created.toNumber() * 1000,
        listed: item.timestamp.listed.toNumber() * 1000,
        redeemed: item.timestamp.redeemed.toNumber() * 1000
      },
    }
  }

  toWineInfoStatus(state) {
    switch (state) {
      case ItemState.ForSale:
        return 1;
      case ItemState.Holded:
        return 2;
      case ItemState.RedeemRequested:
      case ItemState.Redeemed:
        return 3;
      default:
    }
  }

  toWon(usdc) {
    return (usdc * this.exchangeRate).toFixed(1);
  }

  formatUsdc(number) {
    return ethers.utils.formatUnits(number, this.decimals);
  }

  parseUsdc(str) {
    return ethers.utils.parseUnits(str, this.decimals);
  }

  updateExchangeRate() {
    if (this.refreshRate <= 0)
      return;
    setTimeout(() => {
      try {
        if (this.refreshRate > 0)
          this.doUpdateExchangeRate();
      } finally {
        this.updateExchangeRate();
      }
    }, this.refreshRate);
  }

  async doUpdateExchangeRate() {
    const res = (await axios.get("https://api.coinpaprika.com/v1/tickers/usdc-usd-coin?quotes=KRW")).data;
    this.exchangeRate = res.quotes.KRW.price;
  }

  /**
   * Membership 구매기능 함수.
   *
   * @param {number} count 구매 수량.
   * @param {(PurchaseStep) => void} progress 진행상태를 표시하는 콜백함수
   * @return {number} 구매한 멤버쉽 목록.
  */
  async purchaseMembership(count, progress) {
    progress(PurchaseStep.Start);
    const { currency, publisher, account } = await this.ensureConnection(true);
    if (await publisher.paused()) {
      throw new UserFriendlyError("판매 시작 전 입니다.");
    }

    const price = await publisher["price()"]();
    const totalPrice = price.mul(count);
    if (totalPrice.gt(await currency.balanceOf(account))) {
      throw new UserFriendlyError("잔고가 부족합니다.");
    }

    if ((await currency.allowance(account, publisher.address)).lt(totalPrice)) {
      progress(PurchaseStep.RequrireSign);
      await wait(currency.approve(publisher.address, totalPrice));
      progress(PurchaseStep.CompleteSign);
    }
    progress(PurchaseStep.RequrirePurchase);
    const receipt = await wait(publisher.purchase(count));
    progress(PurchaseStep.CompletePurchase);
    const events = filter(
      receipt.logs
      , "event Purchase(address indexed buyer, address indexed collection, uint256 indexed tokenId)"
    );
    return events.map(v => `${v.args.collection}/${v.args.tokenId}`);
  }

  async isMembershipOnSale() {
    const { publisher } = await this.ensureConnection();
    return !await publisher.paused();
  }

  async* fetchMyMemberships() {
    const { membership, account } = await this.ensureConnection(true);
    const enumerable = new ethers.Contract(
      membership.address, ERC721Enumerable.abi, membership.provider.getSigner()
    );
    const balance = await enumerable.balanceOf(account);

    for (let i = 0; balance.gt(i); ++i) {
      yield `${enumerable.address}/${await enumerable.tokenOfOwnerByIndex(account, i)}`;
    }
  }

  async fetchMembershipMetadata(tokenAddress) {
    const tokenId = tokenAddress.split('/').at(-1);
    const { membership } = await this.ensureConnection();
    const meta = await this.fetchMetadata(membership, tokenId);
    if (meta?.image?.startsWith("ipfs://")) {
      meta.image = `https://lcwc.infura-ipfs.io/ipfs/${meta.image.substr("ipfs://".length)}`;
    }
    return meta;
  }

  async getRemainMembershipNfts() {
    const { publisher } = await this.ensureConnection(true);
    return await publisher.remains();
  }
}

export default function getContractApi() {
  if (global.winexContractApi === undefined) {
    global.winexContractApi = new ContractApi();
  }
  return global.winexContractApi;
}

export const ItemState = {
  Holded: 0,
  ForSale: 1,
  RedeemRequested: 2,
  Redeemed: 3,
}

export const PurchaseStep = {
  Start: 0,
  RequrireSign: 1,
  CompleteSign: 2,
  RequrirePurchase: 3,
  CompletePurchase: 4,
  Fail: 5
}

export const WineMintingStep = {
  Start: 0,
  Progress: 1,
  ImageUpload: 2,
  CreateMetaData: 3,
  Minting: 4,
  CheckOwner: 5,
  End: 10,
  Fail: 30,
  Fail_ImageUpload: 31,
  Fail_MetadataUpload: 32,
  Fail_ownerDifferent: 33,
}

class UserFriendlyError extends Error {
  constructor(message, cause) {
    super(message);
    this.name = "UserFriendlyError";
    this.cause = cause;
  }
}
